import Head from 'next/head';
import { useRouter } from 'next/router';

import { Box, Button } from '@chakra-ui/react';
import { Error, Home } from '@styled-icons/boxicons-regular';
import type { NextPage } from 'next';

import { DefaultLayout } from '@layouts/index';

const Page404: NextPage = () => {
  const router = useRouter();
  const onBackToHome = () => router.push('/');
  return (
    <>
      <Head>
        <title>Sherdtrip</title>
        <meta name="description" content="No need to plan your trip" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <DefaultLayout
        pt="80px"
        color="black"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Error color="#484848" size="160px" />
        <Box as="h1">Lỗi 404</Box>
        <Box as="p" pb="4">
          Rất tiếc, trang không tìm thấy
        </Box>
        <Button onClick={onBackToHome} leftIcon={<Home size="18px" />}>
          Về trang chủ
        </Button>
      </DefaultLayout>
    </>
  );
};
export default Page404;
